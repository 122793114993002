import React, {useState, useEffect} from 'react'
import {
  Grid,
  makeStyles,
  Tabs,
  Tab,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    background: '#fff',
    boxShadow: theme.shadows[10],
  },
  tab: {
    minWidth: '50%',
    background: theme.palette.primary.main,
    color: '#fff',
  },
}))

const TabNavigation = () => {

  const styles = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const routes = [
    {
      name: 'Jugadores',
      link: '/inicio/jugadores',
      icon: <PeopleOutlineIcon/>,
      value: 0,
    },
    {
      name: 'Asistencia',
      link: '/inicio/asistencia',
      icon: <AssistantPhotoIcon/>,
      value: 1,
    }
  ]

  useEffect(() => {
    switch(window.location.pathname){
      case '/inicio/jugadores':
        setValue(0)
      break
      case '/inicio/asistencia':
        setValue(1)
      break
      default:
      break
    }
  }, [value, setValue])

  return (
    <Grid item className={styles.container}>
      <Tabs
        value={value}
        indicatorColor='primary'
        onChange={handleChange}
      >
        {
          routes.map((route, i) => (
            <Tab
              key={`${route.name}${route.value}`}
              className={styles.tab}
              component={Link}
              icon={route.icon}
              to={route.link}
              label={route.name}
            />
          ))
        }
      </Tabs>
    </Grid>
  )
}

export default TabNavigation