import {useState, useEffect, useCallback} from 'react'
import {
  Grid,
  Button,
  Typography,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  Paper,
  makeStyles,
  TableContainer,
  TextField,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Collapse,
  useTheme,
  useMediaQuery,
} from '@material-ui/core'
import {Alert} from '@mui/material'
import {connect} from 'react-redux'
import { getAsistencias } from '../reducers/asistencia'
import { Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { getJugadores } from '../reducers/jugadores'
import CustomModal from '../components/CustomModal'
import FetchButton from '../components/FetchButton'
import Input from '../components/Input'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@material-ui/icons/Close'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

import QrReader from 'react-qr-reader'

const useStyles = makeStyles(theme => ({
  titleContainer: {
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1em',
    },
  },
  container: {
    marginTop: '1em',
    width: '90%',
  },
  tableContainer: {
    width: '100%',
    boxShadow: theme.shadows[5],
    overflowY: 'scroll',
    maxHeight: '15em',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '20em',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '24em'
    },
  },
  table: {
    boxShadow: theme.shadows[5],
  },
  tableRow: {
    '&:hover': {
      background: '#ddd',
      cursor: 'pointer',
    },
  },
  input: {
    marginBottom: '1em',
  },
  bottomContainer: {
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '7em',
    }
  },
}))

const Lector = ({handleClose, scanData, setScanData}) => {

  const handleError = error => {
    console.log(error)
  }

  const handleScan = data => {
    if(data){
      setScanData(data)
    }
  }

  return (
    <Card>
      <CardContent>
        <Grid
          container
          justifyContent='flex-end'
        >
          <IconButton
            aria-label="close icon"
            component="span"
            style={{background: 'transparent'}}
            disableRipple
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          style={{width: '13em', height: '15em'}}
          direction='column'
          justifyContent='center'
        >
          <QrReader
            delay={300}
            style={{marginBottom: '10em'}}
            onError={handleError}
            onScan={handleScan}
          />
        </Grid>
      </CardContent>
    </Card>
  )
}

const Form = ({handleClose}) => {
  const {submitForm} = useFormikContext()
  return (
    <Card>
      <CardContent>
        <Grid
          container
          style={{width: '13em', textAlign: 'center'}}
          direction='column'
          justifyContent='center'
        >
          <Grid item>
            <Typography variant='h2' style={{fontSize: '1.5rem'}}>Guardar lista</Typography>
          </Grid>
          <Grid item>
            <Input
              label="Fecha"
              type='date'
              fieldName='date'
            />
          </Grid>
          <Grid item>
            <Input
              label="Hora"
              type='time'
              fieldName='hour'
            />
          </Grid>
          <Grid item>
            <Input
              label="Observaciones"
              type='text'
              multiline
              fieldName='observations'
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{justifyContent: 'center'}}>
        <FetchButton
          onClick={submitForm}
        >
          Guardar
        </FetchButton>
        <Button variant='contained' size='small' onClick={handleClose}>Cerrar</Button>
      </CardActions>
      <CardContent>
        <Grid
          container
          style={{width: '13em', textAlign: 'justify'}}
          direction='column'
          justifyContent='center'
        >
          <Typography variant='subtitle1'><small>Usa ves guardada la lista no podra ser editada ni eliminada</small></Typography>
        </Grid>
      </CardContent>
    </Card>
  )
}

const FormikForm = ({handleClose, setRows, setOpenAlert, setAlertInfo, getAsistencias, getJugadores, ...rest}) => {

  const {hoy} = rest

  return (
    <Formik
      onSubmit={(x, {resetForm}) => {

        const date = x.date.split('-')
        date.shift()
        date.shift()
        const day = parseInt(date[0])
        
        const dateHoy = hoy.split('-')
        dateHoy.shift()
        dateHoy.shift()
        const d = parseInt(dateHoy[0])
        
        const horaEntrada = x.hour.split(':')
        const [horaE, minutosE] = horaEntrada
        const parseHoraE = parseInt(horaE)
        const parseMinutosE = parseInt(minutosE)
        const {jugadores, list} = rest
        const newList = []

        let newHoraE

        if(parseHoraE >= 13 || parseHoraE === 0){
          newHoraE = parseHoraE === 0 ? `12 : ${parseMinutosE} AM` : `${parseHoraE - 12}:${parseMinutosE} PM`
        }else{
          newHoraE = `${parseHoraE} : ${parseMinutosE} AM`
        }

        list.map(x => {
          jugadores.map((jugador, index) => {
            if(x.ID === jugador.ID){
              jugadores.splice(index, 1)
            }
            return 0
          })
          const hour = x.hour.split(' ')
          hour.splice(1, 1)
          const [hora, minutos, meridiano] = hour
          let newHora
          const parseHora = parseInt(hora)
  
          if(meridiano === 'AM'){
            newHora = parseHora === 12 ? 0 : parseHora
          }else{
            newHora = parseHora === 12 ? 12 : parseHora + 12
          }
  
          let state
          if(day > d){
            state = 'Temprano'
          }else if(day < d){
            state = 'Tarde'
          }else{
            if(newHora <= parseHoraE){
              if(newHora === parseHoraE){
                state = minutos <= parseMinutosE ? 'Temprano' : 'Tarde'
              }else{
                state = 'Temprano'
              }
            }else{
              state = 'Tarde'
            }
          }
          newList.push({
            ...x,
            state,
          })
          return 0
        })

        jugadores.map(jugador => {
          newList.push({
            ...jugador,
            hour: '---',
            date: '---',
            state: 'No asistio',
          })
          return 0
        })

        const data = {
          ...x,
          hour: newHoraE,
          list: newList,
        }

        fetch('https://control-access-backend.vercel.app/api/asistencias/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sessionStorage.getItem('token'),
            role: sessionStorage.getItem('role'),
          },
          body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(res => {
          getAsistencias()
          getJugadores()
          setAlertInfo(res)
          setOpenAlert(true)
        })

        setRows([])
        localStorage.removeItem('rows')
        resetForm({x: ''})
        handleClose()
      }}
      validationSchema={
        Yup.object({
          date:
            Yup.string()
            .required('La fecha del evento es requerida.'),
          hour:
            Yup.string()
            .required('La hora del evento es requerida.'),
          observations:
            Yup.string()
            .max(250, 'Solo se permite un maximo de 250 caracteres.'),
        })
      }
      initialValues={{
        date: hoy,
        hour: '',
        observations: '',
      }}
    >
      <Form handleClose={handleClose}/>
    </Formik>
  )
}

const NewListaAsistencia = ({jugadores, getJugadores, getAsistencias}) => {

  const [rows, setRows] = useState(localStorage.getItem('rows') ? JSON.parse(localStorage.getItem('rows')) : [])
  const listaJugadores = jugadores.data
  const styles = useStyles()
  const {fetched} = jugadores
  const [openModal, setOpenModal] = useState(false)
  const [value, setValue] = useState('')
  const [infoModal, setInfoModal] = useState(<></>)
  const [time, setTime] = useState(0)
  const [openAlert, setOpenAlert] = useState(false)
  const [alertInfo, setAlertInfo] = useState(null)
  const [scanData, setScanData] = useState('')

  const theme = useTheme()
  const mediaSM = useMediaQuery(theme.breakpoints.down('sm'))
  
  const now = new Date()
  const y = now.getFullYear()
  const m = now.getMonth() + 1
  const d = now.getDate()
  const hoy = `${y}-${m}-${d}`

  useEffect(() => {
    if(!fetched){
      getJugadores()
    }
  }, [fetched, getJugadores])

  const handleOpen = (contenido) => {
    setInfoModal(contenido)
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleChange = e => {
    setValue(e.target.value)
  }

  const addJugador = () => {
    let jugadorExist = false
    let jugador
    jugadores.res.jugadores.map(x => {
      if(x.ID === value){
        jugador = x
        jugadorExist = true
      }
      return jugadorExist
    })
    let jugadorInList = false
    rows.map(x => {
      if(x.ID === value){
        jugadorInList = true
      }
      return jugadorInList
    })
    const lista = rows.concat({...jugador, hour: time, date: hoy})
    if(jugadorExist){
      if(jugadorInList){
        setAlertInfo({
          alert: 'warning',
          msg: 'El jugador ya se encuentra registrado en la lista de asistencia.',
        })
        setOpenAlert(true)
      }else{
        localStorage.setItem('rows', JSON.stringify(lista))
        setRows(lista)
        setAlertInfo({
          alert: 'success',
          msg: 'El jugador se agrego a la lista de asistencia.',
        })
        setValue('')
        setOpenAlert(true)
      }
    }else{
      setAlertInfo({
        alert: 'warning',
        msg: 'La identificacion del jugador es incorrecta o el jugador no a sido registrado en la lista de jugadores del equipo.',
      })
      setOpenAlert(true)
    }
  }

  useEffect(() => {
    if(scanData){
      console.log(scanData)
      setValue(scanData)
      setOpenModal(false)
    }
  }, [scanData])

  const handleDelete = jugador => {
    const arr = []
    rows.map(x => {
      return x.ID === jugador.ID ? console.log('si') : arr.push(x)
    })
    localStorage.setItem('rows', JSON.stringify(arr))
    setRows(arr)
  }

  const reloj = useCallback(() => {

    const fecha = new Date()
    let hora = fecha.getHours()
    let minuto = fecha.getMinutes()
    let meridiano = "AM"

    if(hora === 0){
      hora = 12;
    }
    if(hora > 12){ 
      hora = hora - 12;
      meridiano = "PM"
    }

    hora = (hora < 10) ? "0" + hora : hora
    minuto = (minuto < 10) ? "0" + minuto : minuto

    let horaImprimible = hora + " : " + minuto + " " + meridiano
   
    setTime(horaImprimible)

    setTimeout(() => {
      reloj()
    }, 5000)
  }, [])

  useEffect(() => {
    reloj()
  }, [time, reloj])

  return (
    <>
      <Grid container justifyContent='center'>
        <Grid item>
          {
            // FIXME: in esta dando error en conlola 
          }
          <Collapse in={openAlert}>
            <Alert
              severity={alertInfo ? alertInfo.alert : 'success'}
              action={
                <IconButton
                  color='inherit'
                  size="small"
                  onClick={() => setOpenAlert(false)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {alertInfo ? alertInfo.msg : ''}
            </Alert>
          </Collapse>
        </Grid>
      </Grid>
      <Grid item container justifyContent='flex-start' className={styles.titleContainer}>
        <Grid item>
          <Typography variant='h2'>Nueva lista de asistencia</Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction='column'
        className={styles.container}
      >
        <Grid
          container
          justifyContent='space-between'
          style={{marginBottom: '0.5em'}}
        >
          <Grid
            item
            container
            sm={9}
          >
            <Grid item>
              <TextField
                id="standard-size-small"
                size="small"
                type='text'
                style={{width: '100px'}}
                variant="standard"
                onChange={handleChange}
                value={value}
              />
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                size='small'
                style={{marginLeft: '1em'}}
                onClick={() => handleOpen(
                  <Lector
                    handleClose={handleClose}
                    scanData={scanData}
                    setScanData={setScanData}
                  />
                )}
              >
                <QrCodeScannerIcon/>
              </Button>
              <Button
                variant='contained'
                color='primary'
                size='small'
                style={{marginLeft: '1em'}}
                onClick={addJugador}
              >
                <AddIcon/>
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent={mediaSM ? 'flex-start' : 'flex-end'}
            style={{marginTop: mediaSM ? '1em' : 0}}
            sm={3}
          >
            <Button
              variant='contained'
              color='primary'
              disableElevation
              size='small'
              style={{marginRight: '0.5em'}}
              onClick={() => rows.length <= 0 ? console.log('La lista esta vacia') : handleOpen(
                <FormikForm
                  handleClose={handleClose}
                  hoy={hoy}
                  list={rows}
                  jugadores={listaJugadores}
                  setRows={setRows}
                  setOpenAlert={setOpenAlert}
                  setAlertInfo={setAlertInfo}
                  getAsistencias={getAsistencias}
                  getJugadores={getJugadores}
                />
              )}
            >
              Guardar lista
            </Button>
          </Grid>
        </Grid>
        <Grid item className={styles.tableContainer}>
          <TableContainer component={Paper} className={styles.table}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>#</TableCell>
                  <TableCell align="left">Full name</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Fecha</TableCell>
                  <TableCell align="right">Hora de llegada</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow
                    key={row.ID}
                    className={styles.tableRow}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <HighlightOffIcon
                        style={{verticalAlign: 'middle'}}
                        onClick={() => handleDelete(row)}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">{++i}</TableCell>
                    <TableCell style={{minWidth: '10em'}} align="left">{row.fullname}</TableCell>
                    <TableCell style={{minWidth: '7em'}} component="th" scope="row">{row.ID}</TableCell>
                    <TableCell align="right">{row.date}</TableCell>
                    <TableCell align="right">{row.hour}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid
        item
        container
        className={styles.bottomContainer}
        justifyContent='flex-start'
      >
        <Grid
          item
          container
          justifyContent='space-between'
        >
          <Grid item>
            <Typography variant='subtitle1' style={{marginTop: '0.3em'}}>{`Fecha: ${hoy}`}</Typography>
          </Grid>
          <Grid item>
            <Typography variant='subtitle1' style={{marginTop: '0.3em'}}>{`Hora: ${time}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <CustomModal openModal={openModal} handleClose={handleClose}>
        {infoModal}
      </CustomModal>
    </>
  )
}

const mapStateToProps = state => ({
  jugadores: state.jugadores,
})

const mapDispatchToProps = dispatch => ({
  getJugadores: () => dispatch(getJugadores()),
  getAsistencias: () => dispatch(getAsistencias()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NewListaAsistencia)