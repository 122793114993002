const initialState = {
  fetching: false,
  fetched: false,
  error: false,
  alert: false,
  res: null,
  data: null,
}

const ASISTENCIA_START = 'ASISTENCIA_START'
const ASISTENCIA_SUCCESS = 'ASISTENCIA_SUCCESS'
const ASISTENCIA_ERROR = 'ASISTENCIA_ERROR'

const reducer = (state = initialState, action) => {
  switch(action.type){
    case ASISTENCIA_START:
      return {
        ...state,
        fetching: true,
        alert: false,
      }
    case ASISTENCIA_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        alert: true,
        res: {...action.payload},
        data: [...action.payload.asistencia]
      }
    case ASISTENCIA_ERROR:
      return {
        ...state,
        fetching: false,
        fetched: true,
        alert: true,
        error: true,
        res: {...action.payload},
      }
    default:
      return state
  }
}

export default reducer

const asistenciaStart = () => ({
  type: ASISTENCIA_START,
})

const asistenciaSuccess = payload => ({
  type: ASISTENCIA_SUCCESS,
  payload,
})

const asistenciaError = payload => ({
  type: ASISTENCIA_ERROR,
  payload,
})

export const getAsistencias = () => async (dispatch) => {
  dispatch(asistenciaStart())
  try{
    const token = sessionStorage.getItem('token')
    const role = sessionStorage.getItem('role')
    const res = await fetch('https://control-access-backend.vercel.app/api/asistencias/get', {
      headers: {
        Authorization: token,
        role,
      }
    })
    const data = await res.json()
    dispatch(data.status === 200 ? asistenciaSuccess(data) : asistenciaError(data))
  }catch(err){
    dispatch(asistenciaError({
      status: 500,
      title: 'Error',
      alert: 'error',
      msg: 'A ocurrido un error favor intentelo mas tarde',
    }))
  }
}
