import React from 'react'
import {
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  footer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    height: '2em',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      height: '3em',
    },
  },
  copywrite: {
    fontSize: '1em',
    fontWeight: 400,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      marginLeft: '3em',
      marginRight: '3em',
    },
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
}))

const Footer = () => {

  const styles = useStyles()
  const date = new Date()

  return (
    <Grid item style={{height: '5vh'}}>
      <footer className={styles.footer}>
        <Typography className={styles.copywrite}>
          © {date.getFullYear()} | All rights reserved | Powered by {`${' '}`}
          <Typography
            component='a'
            href='https://www.youtube.com/'
            rel='noopener noreferrer'
            target='_blank'
            className={styles.link}
          >
            TechTeks.net
          </Typography>
        </Typography>
      </footer>
    </Grid>
  )
}

export default Footer