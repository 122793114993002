import { Grid, Hidden, useMediaQuery, useTheme } from '@material-ui/core'
import {Route, Switch, Redirect} from 'react-router-dom'
import Header from '../components/Header'
import Navigation from '../components/Navigation'
import Jugadores from './Jugadores'
import Asistencia from './Asistencia'
// import Cuenta from './Cuenta'
import Reportes from './Reportes'
import TabNavigation from '../components/TabNavigation'
import Footer from '../components/Footer'

const Inicio = () => {

  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container direction='column' style={{height: matchesSM ? undefined : '100vh'}}>
      <Header/>
      <Grid
        item
        container
        style={{height: matchesSM ? undefined : '85vh'}}
        direction='row'
      >
        <Hidden smDown><Navigation/></Hidden>
        <Switch>
          <Redirect exact from='/inicio' to='/inicio/jugadores'/>
          <Route exact path='/inicio/jugadores' component={Jugadores}/>
          <Route path='/inicio/asistencia' component={Asistencia}/>
          <Route path='/inicio/reportes' component={Reportes}/>
          {/* <Route exact path='/inicio/cuenta' component={Cuenta}/> */}
          <Redirect from='*' to='/404'/>
        </Switch>
      </Grid>
      <Hidden smDown><Footer/></Hidden>
      <Hidden mdUp><TabNavigation/></Hidden>
    </Grid>
  )
}

export default Inicio