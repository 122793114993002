import {useState, useEffect, useCallback} from 'react'
import { Route, useHistory, useLocation } from "react-router"
import { connect } from "react-redux"
import { CircularProgress, Grid } from '@material-ui/core'

const PrivateRoute = ({auth, ...props}) => {
  const token = sessionStorage.getItem('token')
  const role = sessionStorage.getItem('role')
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const location = useLocation()
  const urlGo = '/login'
  const url = location.pathname

  const isAuthenticated = useCallback(async () => {
    const response = await fetch('https://control-access-backend.vercel.app/api/auth/validate-login', {
      headers: {
        Authorization: token,
        role,
      }
    })
    const res = await response.json()
    return res
  }, [token, role])

  useEffect(() => {
    if(token){
      if(url === '/main' && role !== 'expert'){
        history.replace('/login')
      }else{
        isAuthenticated()
        .then(res => {
          res.status === 200 ? console.log() : history.replace('/login')
        })
      }
    }else{
      history.replace(urlGo)
    }
  }, [token, role, url, history, isAuthenticated])

  setTimeout(() => {
    setLoading(false)
  }, 200)

  if(loading){
    return (
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        style={{height: '100vh'}}
      >
        <CircularProgress/>
      </Grid>
    )
  }

  return (
    <Route {...props}/>
  )
}

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)