import {TextField} from '@material-ui/core'
import { useField } from 'formik'

const Input = ({fieldName, ...props}) => {
  const [field, meta] = useField(fieldName)
  return (
    <TextField
      style={{width: '100%', marginTop: '1em'}}
      {...props}
      error={meta.error && meta.touched}
      helperText={meta.touched && meta.error ? meta.error : ''}
      onChange={field.onChange(fieldName)}
      value={field.value}
    />
  )
}

export default Input