import React from 'react'
import {
  Grid,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import {Switch, Route} from 'react-router-dom'
// Screens
import ListasAsistencia from './ListasAsistencias'
import NewListaAsistencia from './NewListaAsistencia'

const Asistencia = () => {

  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid
      item
      container
      direction='column'
      alignItems='center'
      justifyContent='center'
      sm={matchesSM ? 12 : 9}
    >
      <Switch>
        <Route exact path='/inicio/asistencia' component={ListasAsistencia} />
        <Route exact path='/inicio/asistencia/new' component={NewListaAsistencia} />
      </Switch>
    </Grid>
  )
}

export default Asistencia