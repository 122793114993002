const initialState = {
  fetching: false,
  error: false,
  res: null,
}

const LOGIN_START = 'LOGIN_START'
const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
const LOGIN_ERROR = 'LOGIN_ERROR'

const reducer = (state = initialState, action) => {
  switch(action.type){
    case LOGIN_START:
      return {
        ...state,
        error: false,
        fetching: true,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        res: {...action.payload},
      }
    case LOGIN_ERROR:
      return {
        ...state,
        fetching: false,
        error: true,
        res: {...action.payload},
      }
    default:
      return state
  }
}

export default reducer

const loginStart = () => ({
  type: LOGIN_START,
})

const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload,
})

const loginError = payload => ({
  type: LOGIN_ERROR,
  payload,
})

export const login = admin => async (dispatch) => {
  dispatch(loginStart())
  try{
    const response = await fetch('https://control-access-backend.vercel.app/api/auth/login', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(admin),
    })
    const data = await response.json()
    console.log(data)
    if(data.status === 200){
      sessionStorage.setItem('token', data.token)
      sessionStorage.setItem('role', data.role)
      dispatch(loginSuccess(data))
    }else{
      dispatch(loginError(data))
    }
  }catch(err){
    console.error(err)
    dispatch(loginError({
      status: 500,
      title: 'Error',
      alert: 'error',
      msg: 'A ocurrido un error favor intentelo mas tarde',
    }))
  }
}

export const logout = () => async (dispatch) => {
  console.log('logout')
}