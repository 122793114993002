import React from 'react'
import {
  AppBar,
  Grid,
  makeStyles,
  Toolbar,
  Typography,
  useScrollTrigger,
  Button,
  // IconButton,
} from '@material-ui/core'
// import SettingsIcon from '@material-ui/icons/Settings'
// import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'


const ElevationScroll = (props) => {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

const useStyles = makeStyles(theme => ({
  toolbarMargin: {
    ...theme.mixins.toolbar,
  },
}))

const Header = () => {

  const styles = useStyles()
  const history = useHistory()

  const logOut = () => {
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('role')
    history.replace('/login')
  }
  
  return (
    <Grid item>
      <ElevationScroll>
        <AppBar>
          <Toolbar>
            <Typography variant='h1' style={{flexGrow: 1}}>CONTROL-ACCESS-MANAGE</Typography>
            <Button
              variant='text'
              style={{color: '#fff', background: 'transparent'}}
              disableRipple
              onClick={() => logOut()}
            >
              Cerrar sesión
            </Button>
            {/* <IconButton
              aria-label="cuenta"
              component={Link}
              to='/cuenta'
              style={{background: 'transparent'}}
            >
              <SettingsIcon style={{color: '#fff'}}/>
            </IconButton> */}
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={styles.toolbarMargin}></div>
    </Grid>
  )
}

export default Header