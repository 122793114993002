import {
  Grid,
  Button,
  Typography,
  IconButton,
  TableCell,
  TableRow,
  TableBody,
  Table,
  TableHead,
  Paper,
  makeStyles,
  TableContainer,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core'
import CustomModal from '../components/CustomModal'
import Input from '../components/Input'
import FetchButton from '../components/FetchButton'
import {Formik, useFormikContext} from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { getAsistencias } from '../reducers/asistencia'
import { getJugadores } from '../reducers/jugadores'
import { useHistory } from "react-router"

// Iconos
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
// import FilterListIcon from '@material-ui/icons/FilterList'

import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  titleContainer: {
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1em',
    },
  },
  container: {
    marginTop: '1em',
    width: '90%',
  },
  tableContainer: {
    width: '100%',
    boxShadow: theme.shadows[5],
    overflowY: 'scroll',
    maxHeight: '15em',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '20em',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '24em'
    },
  },
  table: {
    boxShadow: theme.shadows[5],
  },
  tableRow: {
    '&:hover': {
      background: '#ddd',
      cursor: 'pointer',
    },
  },
  input: {
    marginBottom: '1em',
  },
  bottomContainer: {
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '7em',
    }
  },
}))

const Form = ({handleClose}) => {
  const {submitForm} = useFormikContext()
  return (
    <Card>
      <CardContent>
        <Grid
          container
          style={{width: '13em', textAlign: 'center'}}
          direction='column'
          justifyContent='center'
        >
          <Grid item>
            <Typography variant='h2' style={{fontSize: '1.5rem'}}>Generar reporte</Typography>
          </Grid>
          <Grid style={{textAlign: 'left', marginTop: '1em'}} item>
            <label>Desde:</label>
            <Input
              label=""
              type='date'
              fieldName='inicio'
            />
          </Grid>
          <Grid style={{textAlign: 'left', marginTop: '1em'}} item>
            <label>Hasta:</label>
            <Input
              type='date'
              fieldName='fin'
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{justifyContent: 'center'}}>
        <FetchButton
          onClick={submitForm}
        >
          Generar
        </FetchButton>
        <Button variant='contained' size='small' onClick={handleClose}>Cerrar</Button>
      </CardActions>
    </Card>
  )
}

const ListasAsistencia = ({asistencias, jugadores, getAsistencias, getJugadores}) => {

  const styles = useStyles()
  const history = useHistory()
  const {fetched} = asistencias
  const asistenciasData = asistencias.data ? asistencias.data : []
  const [page, setPage] = useState(asistencias.data ? asistencias.data.length - 1 : 0)
  const [openModal, setOpenModal] = useState(false)

  const handleClose = () => {
    setOpenModal(false)
  }
  
  useEffect(() => {
    if(!fetched){
      getAsistencias()
    }else{
      if(asistencias.data){
        setPage(asistencias.data.length - 1)
      }else{
        console.log('error')
      }
    }
  }, [getAsistencias, fetched, asistencias.data])

  useEffect(() => {
    if(!fetched){
      getJugadores()
    }
  }, [getJugadores, fetched])
  
  return (
    <>
      <Grid
        item
        container
        direction='column'
        className={styles.container}
      >
        <Grid
          item
          container
          justifyContent='space-between'
          style={{marginBottom: '0.5em'}}
        >
          <Grid item>
            <Typography variant='h2'>Lista de asistencia</Typography>
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              color='primary'
              disableElevation
              size='small'
              style={{marginRight: '0.5em'}}
              onClick={() => setOpenModal(true)}
            >
              Reporte
            </Button>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              size='small'
              style={{marginRight: '0.5em'}}
              component={Link}
              to='/inicio/asistencia/new'
            >
              Nueva lista
            </Button>
          </Grid>
        </Grid>
        <Grid item className={styles.tableContainer}>
          <TableContainer component={Paper} className={styles.table}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell align="left">Full name</TableCell>
                  <TableCell align="right">Fecha</TableCell>
                  <TableCell align="right">Hora</TableCell>
                  <TableCell align="left">Estado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {asistenciasData.map((asistencia, i) => {
                  if(i === page){
                    return (
                      asistencia.list.map((row, i) => (
                        <TableRow
                          key={i}
                          className={styles.tableRow}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">{++i}</TableCell>
                          <TableCell style={{minWidth: '7em'}} component="th" scope="row">{row.ID}</TableCell>
                          <TableCell style={{minWidth: '10em'}}>{row.fullname}</TableCell>
                          <TableCell style={{minWidth: '7em'}} component="th" scope="row"  align="right">{row.date}</TableCell>
                          <TableCell style={{minWidth: '7em'}} component="th" scope="row"  align="right">{row.hour}</TableCell>
                          <TableCell style={{minWidth: '7em'}} component="th" scope="row">{row.state}</TableCell>
                        </TableRow>
                      ))
                    )
                  }
                  return null
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {asistenciasData.map((asistencia, i) => {
        if(i === page){
          return (
            <Grid
              key={i}
              item
              container
              className={styles.bottomContainer}
              justifyContent='space-between'
            >
              <Grid item container>
                <Typography variant='subtitle1' style={{marginTop: '0.3em'}}>Observaciones: {asistencia.observations}</Typography>
              </Grid>
              <Grid
                item
                container
                justifyContent='space-between'
                alignItems='center'
              >
                <Grid item>
                  <Typography variant='subtitle1'>Fecha: {asistencia.date}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='subtitle1'>Hora del evento: {asistencia.hour}</Typography>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    alignItems='center'
                  >
                    <IconButton
                      aria-label="ArrowbackIcon"
                      disableRipple
                      style={{background: 'transparent', padding: 0, marginRight: '0.3em'}}
                      onClick={() => page === asistencias.data.length - 1 ? null : setPage(page + 1)}
                    >
                      <ArrowBackIcon/>
                    </IconButton>
                    <Typography variant='subtitle2'>{page + 1}</Typography>
                    <IconButton
                      aria-label="ArrowFordwardIcon"
                      disableRipple
                      style={{background: 'transparent', padding: 0, marginLeft: '0.3em'}}
                      onClick={() => page === 0 ? null : setPage(page - 1)}
                    >
                      <ArrowForwardIcon/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        }
        return null
      })}
      <CustomModal openModal={openModal} handleClose={handleClose}>
        <Card>
          <Formik
            onSubmit={(x, {resetForm}) => {
              const lista_jugadores = jugadores.data
              console.log(jugadores, lista_jugadores, asistenciasData, x)
              localStorage.setItem('jugadores', JSON.stringify(lista_jugadores))
              localStorage.setItem('asistencias', JSON.stringify(asistenciasData))
              localStorage.setItem('fechas', JSON.stringify(x))
              history.replace('/reportes')
            }}
            validationSchema={
              Yup.object({
                inicio:
                  Yup.date()
                  .required('La fecha de inicio es requerida.'),
                fin:
                  Yup.date()
                  .required('La fecha de fin es requerida.'),
              })
            }
            initialValues={{
              inicio: '',
              fin: '',
            }}
          >
            <Form handleClose={handleClose}/>
          </Formik>
        </Card>
      </CustomModal>
    </>
  )
}

const mapStateToProps = state => ({
  asistencias: state.asistencias,
  jugadores: state.jugadores,
})

const mapDispatchToProps = dispatch => ({
  getAsistencias: () => dispatch(getAsistencias()),
  getJugadores: () => dispatch(getJugadores()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListasAsistencia)