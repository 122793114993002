import React from 'react'
import {
  Grid,
  makeStyles,
  IconButton,
  Typography,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableContainer,
  TableCell,
  TableRow,
} from '@material-ui/core'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn'
import CancelIcon from '@mui/icons-material/Cancel'
import { useHistory } from 'react-router'

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: '2em',
  },
  subtitle: {
    marginTop: '3em',
    marginBottom: '1em',
    width: '90%',
  },
  tableContainer: {
    width: '90%',
    boxShadow: theme.shadows[5],
    overflowY: 'scroll',
    marginBottom: 0,
    maxHeight: '25em',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '30em'
    },
  },
  table: {
    boxShadow: theme.shadows[5],
  },
  tableRow: {
    cursor: 'pointer',
  },
}))

const Reportes = () => {

  const styles = useStyles()
  const history = useHistory()

  const jugadores = JSON.parse(localStorage.getItem('jugadores'))
  const asistencias = JSON.parse(localStorage.getItem('asistencias'))
  const {inicio, fin} =  JSON.parse(localStorage.getItem('fechas'))

  let [inicio_year, inicio_month, inicio_day] = inicio.split('-')
  let [fin_year, fin_month, fin_day] = fin.split('-')
  inicio_year = parseInt(inicio_year)
  inicio_month = parseInt(inicio_month)
  inicio_day = parseInt(inicio_day)
  fin_year = parseInt(fin_year)
  fin_month = parseInt(fin_month)
  fin_day = parseInt(fin_day)

  const list = []



  asistencias.forEach(asistencia => {
    let [lista_year, lista_month, lista_day] = asistencia.date.split('-')
    lista_year = parseInt(lista_year)
    lista_month = parseInt(lista_month)
    lista_day = parseInt(lista_day)

    if(inicio_year < lista_year && lista_year < fin_year){
      list.push(asistencia)
    }else if(inicio_year <= lista_year && lista_year <= fin_year){
      if(inicio_month <= fin_month){
        if(inicio_month < lista_month && lista_month < fin_month){
          list.push(asistencia)
        }else if(inicio_month <= lista_month && lista_month <= fin_month){
          if(inicio_day <= fin_day){
            if(inicio_day <= lista_day && lista_day <= fin_day){
              list.push(asistencia)
            }
          }else{
            if(inicio_month === lista_month && inicio_day < lista_day){
              list.push(asistencia)
            }else if(fin_month === lista_month && lista_day < fin_day){
              list.push(asistencia)
            }
          }
        }
      }else{
        if(inicio_year === lista_year && inicio_month < lista_month){
          list.push(asistencia)
        }else if(fin_year === lista_year && lista_month < fin_month){
          list.push(asistencia)
        }
      }
    }
  })

  console.table(list)

  return (
    <Grid
      container
      style={{height: '100vh'}}      
      alignItems='center'
      direction='column'
    >
      <Grid
        item
        className={styles.title}
      >
        <Typography variant='h2' color='primary'>
          Reporte
        </Typography>
      </Grid>
      <Grid
        item
        container
        className={styles.subtitle}
      >
        <IconButton
          aria-label="go back"
          size='small'
          color='primary'
          style={{backgroundColor: 'transparent'}}
          onClick={() => history.replace('/inicio/asistencia')}
        >
          <ArrowBackOutlinedIcon />
        </IconButton>
        <Typography variant='subtitle2' color='primary'>
          Desde: {inicio} | Hasta: {fin}
        </Typography>
      </Grid>
      <Grid
        item
        container
        justifyContent='center'
        className={styles.tableContainer}
      >
        <TableContainer component={Paper} className={styles.table}>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Jugador</TableCell>
                {list.map((x, i) => (
                  <TableCell key={i} align="center">{x.date}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {jugadores.map((jugador, i) => (
                <TableRow
                  key={i}
                  className={styles.tableRow}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">{++i}</TableCell>
                  <TableCell style={{minWidth: '7em'}} component="th" scope="row">{jugador.ID}</TableCell>
                  <TableCell style={{minWidth: '10em'}}>{jugador.fullname}</TableCell>
                  {list.map(row => (
                    row.list.map((x, i) => {
                      if(x.ID === jugador.ID){
                        switch(x.state){
                          case 'Temprano':
                            return <TableCell key={`cell-${i}`} style={{minWidth: '6em'}} component="th" align='center' scope="row"><CheckCircleIcon style={{color: 'green'}}/></TableCell>
                          case 'Tarde':
                            return <TableCell key={`cell-${i}`} style={{minWidth: '6em'}} component="th" align='center' scope="row"><DoDisturbOnIcon style={{solor: 'gray'}}/></TableCell>
                          case 'No asistio':
                            return <TableCell key={`cell-${i}`} style={{minWidth: '6em'}} component="th" align='center' scope="row"><CancelIcon style={{color: 'red'}}/></TableCell>
                          default:
                            return <TableCell key={`cell-${i}`} style={{minWidth: '6em'}} component="th" align='center' scope="row"><CancelIcon style={{color: 'red'}}/></TableCell>
                        }
                      }
                      return null
                    })
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default Reportes