import {useState, useEffect, useCallback} from 'react'

const useFetch = (url) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const token = sessionStorage.getItem('token')
  const role = sessionStorage.getItem('role')

  const fetchData = useCallback(async () => {
    const response = await fetch(url, {
      headers: {
        Authorization: token,
        role,
      }
    })
    const data = await response.json()
    setData([...data.teams])
    setLoading(false)
  }, [token, url, role])
  
  useEffect(() => {
    fetchData()
  }, [fetchData])
  return {loading, data}
}

export default useFetch