import {ThemeProvider} from '@material-ui/styles'
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import Theme from './assets/css/Theme'
import Login from './screens/Login'
import Main from './screens/Main'
import UndefinedPage from './screens/404'
import Inicio from './screens/Inicio'
import PrivateRoute from './components/PrivateRoute'
import Reportes from './screens/Reportes'

const App = () => {

  return (
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Switch>
          <Route exact path='/login' component={Login}/>
          <PrivateRoute exact path='/main' component={Main}/>
          <PrivateRoute path='/inicio' component={Inicio}/>
          <PrivateRoute path='/reportes' component={Reportes}/>
          <Route exact path='/404' component={UndefinedPage}/>
          <Redirect exact from='/' to='/login'/>
          <Redirect from='*' to='/404'/>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App