import {Modal, Grid} from '@material-ui/core'

const CustomModal = ({openModal, handleClose, children}) => {
  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container justifyContent='center' alignItems="center" style={{height: '100vh'}}>
        <Grid item>
          {children}
        </Grid>
      </Grid>
    </Modal>
  )
}

export default CustomModal