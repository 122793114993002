import React, {useState, useEffect, useCallback} from 'react'
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  CircularProgress,
  IconButton,
  Collapse,
} from '@material-ui/core'
import Alert from '@mui/material/Alert'
import Input from '../components/Input'
import FetchButton from '../components/FetchButton'
import {Formik, useFormikContext} from 'formik'
import * as Yup from 'yup'
import {connect} from 'react-redux'
import { useHistory, useLocation } from "react-router"
import { login } from '../reducers/auth'
import CloseIcon from '@material-ui/icons/Close'

const Form = () => {
  const {submitForm} = useFormikContext()
  return (
    <Card>
      <CardContent>
        <Grid
          container
          style={{width: '13em', textAlign: 'center'}}
          direction='column'
          justifyContent='center'
        >
          <Grid item>
            <Typography variant='h2' style={{fontSize: '2rem'}}>Login</Typography>
          </Grid>
          <Grid item>
            <Input
              label="Username"
              type='text'
              fieldName='username'
            />
          </Grid>
          <Grid item>
            <Input
              label="Equipo"
              type='text'
              fieldName='team'
            />
          </Grid>
          <Grid item>
            <Input
              label="Password"
              type='password'
              fieldName='password'
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{justifyContent: 'center'}}>
        <FetchButton
          onClick={submitForm}
        >
          Login
        </FetchButton>
      </CardActions>
    </Card>
  )
}

const Login = ({submit, auth}) => {

  console.log(auth)

  const token = sessionStorage.getItem('token')
  const role = sessionStorage.getItem('role')
  const [loading, setLoading] = useState(true)
  const [openAlert, setOpenAlert] = useState(false)
  const history = useHistory()
  const location = useLocation()

  const isAuthenticated = useCallback(async () => {
    try{
      const response = await fetch('https://control-access-backend.vercel.app/api/auth/validate-login', {
        headers: {
          authorization: token,
          role,
        }
      })
      const res = await response.json()
      return res
    }catch(err){
      console.error('Error en el fetch del login.', err)
    }
  }, [token, role])

  useEffect(() => {
    if(token){
      isAuthenticated()
      .then(res => {
        if(res){
          res.status === 200 ? history.push(res.path) : console.log()
        }else{
          console.log(res)
          history.replace('login')
        }
      })
    }
  }, [token, history, location.pathname, isAuthenticated, auth.res])

  useEffect(() => {
    if(auth.error){
      setOpenAlert(true)
    }
  }, [auth.error])

  setTimeout(() => {
    setLoading(false)
  }, 300)

  if(loading){
    return (
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        style={{height: '100vh'}}
      >
        <CircularProgress/>
      </Grid>
    )
  }

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      direction='column'
      style={{height: '100vh', backgroundColor: '#eee'}}
    >
      <Grid item>
        <Collapse in={openAlert}>
          <Alert
            severity={auth.error ? auth.res.alert : 'success'}
            action={
              <IconButton
                aria-label="close"
                color='inherit'
                size="small"
                onClick={() => setOpenAlert(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {auth.error ? `${auth.res.title}: ${auth.res.msg}` : ''}
          </Alert>
        </Collapse>
      </Grid>
      <Grid item>
        <Formik
          onSubmit={x => submit(x)}
          validationSchema={
            Yup.object({
              username:
                Yup.string()
                .required('Ingrese su nombre de usuario.')
                .max(15, 'El usuario no puede contener mas de 15 caracteres.')
                .min(5, 'El usuario no puede contener menos de 5 caracteres.'),
              team:
                Yup.string()
                .required('Ingrese el nombre del equipo.')
                .max(15, 'El equipo no puede contener mas de 15 caracteres.')
                .min(2, 'El equipo no puede contener menos de 5 caracteres.'),
              password:
                Yup.string()
                .required('Ingrese su contraseña.')
                .max(15, 'La contraseña no puede contener mas de 15 caracteres.')
                .min(8, 'La contraseña no puede contener menos de 8 caracteres.'),
            })
          }
          initialValues={{
            username: '',
            team: '',
            password: '',
          }}
        >
          <Form/>
        </Formik>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  submit: (admin) => dispatch(login(admin))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)