const initialState = {
  fetching: false,
  fetched: false,
  error: false,
  alert: false,
  res: null,
  data: null,
}

const JUGADORES_START = 'JUGADORES_START'
const JUGADORES_SUCCESS = 'JUGADORES_SUCCESS'
const JUGADORES_ERROR = 'JUGADORES_ERROR'

const reducer = (state = initialState, action) => {
  switch(action.type){
    case JUGADORES_START:
      return {
        ...state,
        fetching: true,
        alert: false,
      }
    case JUGADORES_SUCCESS:
      return {
        ...state,
        fetching: false,
        fetched: true,
        alert: true,
        res: {...action.payload},
        data: [...action.payload.jugadores]
      }
    case JUGADORES_ERROR:
      return {
        ...state,
        fetching: false,
        fetched: true,
        alert: true,
        error: true,
        res: {...action.payload},
      }
    default:
      return state
  }
}

export default reducer

const jugadoresStart = () => ({
  type: JUGADORES_START,
})

const jugadoresSuccess = payload => ({
  type: JUGADORES_SUCCESS,
  payload,
})

const jugadoresError = payload => ({
  type: JUGADORES_ERROR,
  payload,
})

export const getJugadores = () => async (dispatch) => {
  dispatch(jugadoresStart())
  try{
    const token = sessionStorage.getItem('token')
    const role = sessionStorage.getItem('role')
    const res = await fetch('https://control-access-backend.vercel.app/api/jugadores/get', {
      headers: {
        Authorization: token,
        role,
      }
    })
    const data = await res.json()
    dispatch(data.status === 200 ? jugadoresSuccess(data) : jugadoresError(data))
  }catch(err){
    dispatch(jugadoresError({
      status: 500,
      title: 'Error',
      alert: 'error',
      msg: 'A ocurrido un error favor intentelo mas tarde',
    }))
  }
}

export const registerJugador = (jugador) => async (dispatch) => {
  dispatch(jugadoresStart())
  try{
    const token = sessionStorage.getItem('token')
    const role = sessionStorage.getItem('role')
    const res = await fetch('https://control-access-backend.vercel.app/api/jugadores/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token,
        role,
      },
      body: JSON.stringify(jugador),
    })
    const response = await res.json()
    console.log(response)
    response.status === 200 ? dispatch(jugadoresSuccess(response)) : dispatch(jugadoresError(response))
  }catch(err){
    dispatch(jugadoresError({
      status: 500,
      title: 'Error',
      alert: 'danger',
      msg: 'A ocurrido un error y no se pudo registrar al jugador.',
    }))
  }
}

export const deleteJugador = (jugador) => async (dispatch) => {
  dispatch(jugadoresStart())
  console.log(jugador)
  try{
    const token = sessionStorage.getItem('token')
    const role = sessionStorage.getItem('role')
    const res = await fetch('https://control-access-backend.vercel.app/api/jugadores/delete', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token,
        role,
      },
      body: JSON.stringify(jugador)
    })
    const response = await res.json()
    console.log(response)
    response.status === 200 ? dispatch(jugadoresSuccess(response)) : dispatch(jugadoresError(response))
  }catch(err){
    dispatch(jugadoresError({
      status: 500,
      title: 'Error',
      alert: 'danger',
      msg: 'A ocurrido un error y no se pudo registrar al jugador.',
    }))
  }
}