import {Button} from '@material-ui/core'

const FetchButton = ({children, ...props}) => {
  return (
    <Button
      variant='contained'
      color='primary'
      size='small'
      {...props}
    >
      {children}
    </Button>
  )
}

export default FetchButton