import React, { useState, useEffect } from 'react'
import {
  Grid,
  Card,
  CardContent,
  makeStyles,
  Tabs,
  Tab,
} from '@material-ui/core'
// import logo from '../assets/imgs/logo.svg'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  card: {
    background: '#fff',
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down('md')]: {
      marginLeft: '1.5em',
      marginRight: '1.5em',
    },
  },
  title: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  tab: {
    color: theme.palette.primary.main,
    borderWidth: '1px',
  },
}))

const Navigation = () => {

  const styles = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const routes = [
    {
      name: 'Jugadores',
      link: '/inicio/jugadores',
      value: 0,
    },
    {
      name: 'Asistencia',
      link: '/inicio/asistencia',
      value: 1,
    },
    // {
    //   name: 'Reportes',
    //   link: '/inicio/reportes',
    //   value: 2,
    // }
  ]

  useEffect(() => {
    switch(window.location.pathname){
      case '/inicio/jugadores':
        setValue(0)
      break
      case '/inicio/asistencia':
        setValue(1)
      break
      // case '/inicio/reportes':
      //   setValue(2)
      // break
      default:
        setValue(false)
      break
    }
  }, [value, setValue])

  return (
    <Grid item sm={3}>
      <Grid
        container
        style={{height: '100%'}}
        alignItems='center'
        justifyContent='center'
      >
        <Card className={styles.card}>
          <CardContent>
            {/* <Grid container direction='column'>
              <Grid item>
                <img alt='logo' src={logo} width='100%' height='150em'/>
              </Grid>
              <Grid item>
                <Typography variant='h1' className={styles.title}>San Martin F.C.</Typography>
              </Grid>
            </Grid> */}
            <Grid
              item
              style={{marginTop: '1em'}}
            >
              <Tabs
                value={value}
                orientation='vertical'
                indicatorColor='primary'
                onChange={handleChange}
              >
                {
                  routes.map((route, i) => (
                    <Tab
                      key={`${route.name}${route.value}`}
                      className={styles.tab}
                      component={Link}
                      to={route.link}
                      label={route.name}
                    />
                  ))
                }
              </Tabs>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Navigation