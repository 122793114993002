import React, {useState} from 'react'
import {
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Button,
  Modal,
} from '@material-ui/core'
import Header from '../components/SoporteHeader'
import Input from '../components/Input'
import FetchButton from '../components/FetchButton'
import {connect} from 'react-redux'
import {Formik, useFormikContext} from 'formik'
import * as Yup from 'yup'
import useFetch from '../hooks/useFetch'

const useStyles = makeStyles(theme => ({
  tableContainer: {
    width: '90%',
    boxShadow: theme.shadows[5],
    overflowY: 'scroll',
    height: '60%',
  },
  tableRow: {
    '&:hover': {
      background: '#ddd',
      cursor: 'pointer',
    },
  },
  buttonContainer: {
    width: '90%',
    marginBottom: '1em',
  },
}))

const Form = ({handleClose}) => {
  const {submitForm} = useFormikContext()
  return (
    <Card>
      <CardContent>
        <Grid
          container
          style={{width: '13em'}}
          direction='column'
          justifyContent='center'
        >
          <Grid>
            <Typography variant='h2'>Registrar empresa</Typography>
          </Grid>
          <Grid item>
            <Input
              label="Team"
              type='text'
              fieldName='team'
            />
          </Grid>
          <Grid item>
            <Input
              label="Username"
              type='text'
              fieldName='username'
            />
          </Grid>
          <Grid item>
            <Input
              label="Password"
              type='password'
              fieldName='password'
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{justifyContent: 'center'}}>
        <FetchButton
          onClick={submitForm}
        >
          Registrar
        </FetchButton>
        <Button variant='contained' size='small' onClick={handleClose}>Cerrar</Button>
      </CardActions>
    </Card>
  )
}

const Main = ({login}) => {
  const {loading, data: rows} = useFetch('https://control-access-backend.vercel.app/api/teams/get')
  console.log(loading, rows)

  const styles = useStyles()
  const [openModal, setOpenModal] = useState(false)
  
  const handleOpen = () => {
    setOpenModal(true)
  }
  
  const handleClose = () => {
    setOpenModal(false)
  }


  
  return (
    <>
      <Grid
        container
        alignItems='center'
        justifyContent='center'
        direction='column'
        style={{height: '100vh'}}
      >
        <Header/>
        <Grid
          item
          container
          className={styles.buttonContainer}
          justifyContent='flex-end'
        >
          <Grid item>
            <Button
              variant='contained'
              color='primary'
              size='small'
              onClick={handleOpen}
            >
              Registrar Empresa
            </Button>
          </Grid>
        </Grid>
        <Grid item className={styles.tableContainer}>
          <TableContainer component={Paper} className={styles.table}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">#</TableCell>
                  <TableCell align="left">Team</TableCell>
                  <TableCell align="left">Access</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={row.team}
                    onClick={() => console.log(row.id)}
                    className={styles.tableRow}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell style={{minWidth: '7em'}} component="th" scope="row">{++index}</TableCell>
                    <TableCell style={{minWidth: '10em'}} align="left">{row.team}</TableCell>
                    <TableCell style={{minWidth: '7em'}} align="left">{row.access ? 'true' : 'false'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container justifyContent='center' alignItems="center" style={{height: '100vh'}}>
          <Grid item>
            <Formik
              onSubmit={x => {
                fetch('https://control-access-backend.vercel.app/api/teams/register',{
                  method: 'POST',
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(x)
                })
                .then(res => res.json())
                .then(res => console.log(res))
              }}
              validationSchema={
                Yup.object({
                  team:
                    Yup.string()
                    .required('Ingrese su nombre de usuario.')
                    .max(15, 'El equipo no puede contener mas de 15 caracteres.')
                    .min(5, 'El equipo no puede contener menos de 5 caracteres.'),
                  username:
                    Yup.string()
                    .required('Ingrese su nombre de usuario.')
                    .max(15, 'El usuario no puede contener mas de 15 caracteres.')
                    .min(5, 'El usuario no puede contener menos de 5 caracteres.'),
                  password:
                    Yup.string()
                    .required('Ingrese su contraseña.')
                    .max(15, 'La contraseña no puede contener mas de 15 caracteres.')
                    .min(8, 'La contraseña no puede contener menos de 8 caracteres.'),
                })
              }
              initialValues={{
                team: '',
                username: '',
                password: '',
              }}
            >
              <Form handleClose={handleClose}/>
            </Formik>
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}
const mapStateToProps = state => ({
  login: state.login
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Main)