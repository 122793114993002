import { createTheme } from "@material-ui/core"

const primary = '#607d8b'
// const danger = '#f44336'
const arcOrange = '#FFBA60'
const arcGray = '#868686'

export default createTheme({
  palette: {
    common: {
      grayBlue: primary,
      orange: arcOrange
    },
    primary: {
      main: primary,
    },
    secondary: {
      main: '#fff',
    },
  },
  typography: {
    h1: {
      fontWeight: 700,
      fontSize: '1.5rem',
    },
    h2: {
      fontWeight: 500,
      fontSize: '1.35rem',
      color: primary,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 200,
      color: primary,
    },
    h4: {
      fontSize: '1.75rem',
      color: primary,
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 300,
      color: arcGray,
    },
    subtitle2: {
      fontSize: '1rem',
      fontWeight: 500,
      color: arcGray,
    },
  }
})