import React, {useEffect, useState} from 'react'
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  Card,
  CardContent,
  CardActions,
  useMediaQuery,
  useTheme,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
} from '@material-ui/core'
import {Alert} from '@mui/material'
import QRcode from 'react-qr-code'
import Input from '../components/Input'
import FetchButton from '../components/FetchButton'
import {Formik, useFormikContext} from 'formik'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { getJugadores, registerJugador, deleteJugador } from '../reducers/jugadores'
import CustomModal from '../components/CustomModal'
import CloseIcon from '@material-ui/icons/Close'
// Imports para hacer archivos pdf
// import ReactPDF, { PDFViewer } from '@react-pdf/renderer'
// import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles para darle stilos al archivo pdf
// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'row',
//     backgroundColor: '#E4E4E4'
//   },
//   section: {
//     margin: 10,
//     padding: 10,
//     flexGrow: 1
//   }
// })

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '1em',
    },
  },
  tableContainer: {
    marginTop: '1em',
    width: '90%',
    boxShadow: theme.shadows[5],
    overflowY: 'scroll',
    marginBottom: 0,
    maxHeight: '15em',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '20em',
      marginBottom: '7em',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: '24em'
    },
  },
  table: {
    boxShadow: theme.shadows[5],
  },
  tableRow: {
    '&:hover': {
      background: '#ddd',
      cursor: 'pointer',
    },
  },
  input: {
    marginBottom: '1em',
  },
  infoContainer: {
    marginLeft: '2em',
    marginRight: '2em',
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
}))

const calcularEdad = (birth) => {
  const hoy = new Date()
  const birthday = new Date(birth)
  let edad = hoy.getFullYear() - birthday.getFullYear()
  const month = hoy.getMonth() - birthday.getMonth()
  if (month < 0 || (month === 0 && hoy.getDate() < birthday.getDate())) {
    edad--
  }
  return edad
}

// Formato del archivo pdf
// const MyDocument = (
//   <Document>
//     <Page size="A4" style={styles.page}>
//       <View style={styles.section}>
//         <Text>Section #1</Text>
//       </View>
//       <View style={styles.section}>
//         <Text>Section #2</Text>
//       </View>
//     </Page>
//   </Document>
// )

const Form = ({handleClose}) => {
  const {submitForm} = useFormikContext()
  return (
    <Card>
      <CardContent>
        <Grid
          container
          style={{width: '13em', textAlign: 'center'}}
          direction='column'
          justifyContent='center'
        >
          <Grid item>
            <Typography variant='h2' style={{fontSize: '1.5rem'}}>Registrar jugador</Typography>
          </Grid>
          <Grid item>
            <Input
              label="Nombre completo"
              type='text'
              fieldName='fullname'
            />
          </Grid>
          <Grid item>
            <Input
              label="Identificación"
              type='text'
              fieldName='ID'
            />
          </Grid>
          <Grid item>
            <Input
              label="Nacionalidad"
              type='text'
              fieldName='country'
            />
          </Grid>
          <Grid item>
            <Input
              label="Fecha de nacimiento"
              type='date'
              fieldName='birth'
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{justifyContent: 'center'}}>
        <FetchButton
          onClick={submitForm}
        >
          Registrar
        </FetchButton>
        <Button variant='contained' size='small' onClick={handleClose}>Cerrar</Button>
      </CardActions>
    </Card>
  )
}

const FormikForm = ({handleClose, registerJugador, setOpenAlert}) => {
  return (
    <Formik
      onSubmit={(x, {resetForm}) => {
        const date = x.birth.split('-')
        const [year, month, day] = date
        const newDate = `${month}-${day}-${year}`
        const j = {
          ...x,
          birth: newDate,
        }
        registerJugador(j)
        resetForm({x: ''})
        handleClose()
        setOpenAlert(true)
      }}
      validationSchema={
        Yup.object({
          fullname:
            Yup.string()
            .required('Ingrese su nombre de usuario.')
            .max(30, 'El nombre completo no puede contener más de 30 caracteres.')
            .min(10, 'El nombre completo no puede contener menos de 10 caracteres.'),
          ID:
            Yup.string()
            .required('Ingrese su contraseña.')
            .max(60, 'La identificación no puede contener más de 60 caracteres.')
            .min(5, 'La identificación no puede contener menos de 5 caracteres.'),
          birth:
            Yup.date()
            .required('La fecha de nacimiento es requerida.'),
          country:
            Yup.string()
            .required('Ingrese su contraseña.')
            .max(20, 'La nacionalidad no puede contener más de 20 caracteres.')
            .min(3, 'La nacionalidad no puede contener menos de 3 caracteres.'),
        })
      }
      initialValues={{
        fullname: '',
        ID: '',
        birth: '',
        country: ''
      }}
    >
      <Form handleClose={handleClose}/>
    </Formik>
  )
}

const JugadorCard = ({jugador, handleClose, handleOpen, deleteJugador, setOpenAlert}) => {

  const styles = useStyles()
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))

  const Text = ({children}) => {
    return (
      <Typography variant='subtitle2'>{children}</Typography>
    )
  }

  const Span = ({children}) => {
    return <span style={{color: '#000'}}>{children}</span>
  }

  return (
    <Card>
      <CardContent>
        <Grid
          container
          justifyContent='flex-end'
        >
          <IconButton
            aria-label="close icon"
            component="span"
            style={{background: 'transparent'}}
            disableRipple
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          className={styles.infoContainer}
          justifyContent={matchesSM ? 'center' : undefined}
        >
          <Grid item>
            <Grid
              container
              justifyContent='center'
            >
              <Grid item>
                <Text><Span>Nombre:</Span> {jugador.fullname}</Text>
                <Text><Span>Identificación:</Span> {jugador.ID}</Text>
                <Text><Span>Nacionalidad:</Span> {jugador.country}</Text>
                <Text><Span>Fecha:</Span> {jugador.birth}</Text>
                <Text><Span>Edad:</Span> {calcularEdad(jugador.birth)}</Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent='center'
          style={{marginTop: '2em'}}
        >
          <QRcode value={jugador.ID} size={100}/>
        </Grid>
      </CardContent>
      <CardActions style={{justifyContent: 'center', paddingBottom: '2em'}}>
        <Button
          variant='contained'
          size='small'
          onClick={() => {
            deleteJugador(jugador)
            handleClose()
            setOpenAlert(true)
          }}
        >Eliminar jugador</Button>
        {/* Boton para crear el archivo pdf */}
        {/* <Button variant='contained' color='primary' size='small' onClick={() => handleOpen(<PDFViewer>{MyDocument}</PDFViewer>)}>PDF</Button> */}
      </CardActions>
    </Card>
  )
}

const Jugadores = ({jugadores, getJugadores, registerJugador, deleteJugador}) => {

  console.log(jugadores)

  const styles = useStyles()
  const theme = useTheme()
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'))
  const {fetched} = jugadores
  const rows = jugadores.data && (jugadores.res.status === 200 || jugadores.res.status === 400) ? jugadores.data : []

  const [openModal, setOpenModal] = useState(false)
  const [infoModal, setInfoModal] = useState(<></>)
  const [openAlert, setOpenAlert] = useState(false)

  const handleOpen = (contenido) => {
    setInfoModal(contenido)
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  useEffect(() => {
    if(!fetched){
      getJugadores()
    }
  }, [getJugadores, fetched])

  return (
    <>
      <Grid
      key='sjdfls'
        item
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        sm={matchesSM ? 12 : 9}
      >
        <Grid item>
          {
            // FIXME: in esta dando error en conlola 
          }
          <Collapse in={openAlert}>
            <Alert
              severity={jugadores.alert ? jugadores.res.alert : 'success'}
              action={
                <IconButton
                  color='inherit'
                  size="small"
                  onClick={() => setOpenAlert(false)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {jugadores.alert ? `${jugadores.res.title}: ${jugadores.res.msg}` : ''}
            </Alert>
          </Collapse>
        </Grid>
        <Grid item container justifyContent='center'>
          <Grid item container justifyContent='flex-end' className={styles.buttonContainer}>
            <Grid
              item
              container
              justifyContent='space-between'
            >
              <Grid item>
                <Typography variant='h2'>Lista de jugadores</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant='contained'
                  color='primary'
                  disableElevation
                  size='small'
                  onClick={() => handleOpen(<FormikForm handleClose={handleClose} registerJugador={registerJugador} setOpenAlert={setOpenAlert}/>)}
                >
                  Registrar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={styles.tableContainer}>
          <TableContainer component={Paper} className={styles.table}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell align="left">Nombre completo</TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Edad</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow
                    key={row.ID}
                    onClick={() => handleOpen(<JugadorCard jugador={row} deleteJugador={deleteJugador} handleOpen={handleOpen} handleClose={handleClose} setOpenAlert={setOpenAlert}/>)}
                    className={styles.tableRow}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">{++i}</TableCell>
                    <TableCell align="left">{row.fullname}</TableCell>
                    <TableCell component="th" scope="row">{row.ID}</TableCell>
                    <TableCell align="right">{calcularEdad(row.birth)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <CustomModal openModal={openModal} handleClose={handleClose}>
        {infoModal}
      </CustomModal>
    </>
  )
}

const mapStateToProps = state => ({
  jugadores: state.jugadores,
})

const mapDispatchToProps = dispatch => ({
  getJugadores: () => dispatch(getJugadores()),
  registerJugador: (jugador) => dispatch(registerJugador(jugador)),
  deleteJugador: (jugador) => dispatch(deleteJugador(jugador))
})

export default connect(mapStateToProps, mapDispatchToProps)(Jugadores)