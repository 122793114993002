import {
  Grid,
  makeStyles,
  Typography,
  Button,
} from '@material-ui/core'
import { useHistory } from 'react-router'
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '6rem',
    color: 'rgb(0, 0, 0, 0.5)',
    [theme.breakpoints.down('md')]: {
      fontSize: '5rem',
    },
  },
}))

const UndefinedPage = () => {
  const styles = useStyles()
  const history = useHistory()

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={{height: '100vh'}}
    >
      <Grid
        item
        style={{textAlign: 'center'}}
      >
        <Typography variant='h1' className={styles.title}>Error 404</Typography>
        <Typography variant='body1'>La pagina a la que intenta ingresar no existe.</Typography>
        <Button
          variant="outlined"
          size='small'
          startIcon={<ArrowBackOutlinedIcon />}
          onClick={() => history.goBack()}
        >
          Volver
        </Button>
      </Grid>
    </Grid>
  )
}

export default UndefinedPage